<template>
	<ViewLayout>
		<template #header-title>
			Log Viewer
		</template>
		<template #header-caption>
			The 50 most recent submissions received by the system, ordered by time descending
		</template>
		<template #content>
			<FilterBar
				:search-query.sync="searchQuery"
				search-query-label="Submission"
				hide-brand-filter
			/>
			<SectionCard>
				<template #title>
					Submissions Log
				</template>
				<template #body>
					<DataTable
						:headers="tableHeaders"
						:items="logs"
						:items-per-page="50"
						sort-by="submissionId"
						sort-desc
						hide-default-footer
						height="70vh"
						@click:row="openSubmissionModal"
					>
						<template #item.action="{ item }">
							<IconButton
								tooltip-text="View full submission"
								@click="openSubmissionModal(item)"
							>
								mdi-eye
							</IconButton>
						</template>
					</DataTable>
				</template>
			</SectionCard>
			<Modal
				v-if="showSubmissionStringModal"
				width="800px"
			>
				<template #modal-title>
					SubmissionID: {{ openSubmissionId }}
					<v-spacer />
					<IconButton @click="closeSubmissionModal()">
						mdi-close
					</IconButton>
				</template>
				<template #modal-content>
					<div class="cassie-vertical-md">
						<vue-json-pretty
							v-if="validJson && rawSubmissionString"
							:data="rawSubmissionString"
						/>
						<div v-else>
							{{ rawSubmissionString }}
						</div>
					</div>
				</template>
				<template #modal-footer>
					<SecondaryActionButton @click="closeSubmissionModal">
						Close
					</SecondaryActionButton>
				</template>
			</Modal>
		</template>
	</ViewLayout>
</template>

<script>
import VueJsonPretty from 'vue-json-pretty'
import 'vue-json-pretty/lib/styles.css'
import FilterBar from '../../../../../shared/components/filter-bar.vue'
import ViewLayout from '../../../../../shared/layouts/view-layout.vue'
import SectionCard from '../../../../../shared/components/section-card.vue'
import DataTable from '../../../../../shared/components/data-table.vue'
import { getSubmissionLogs } from '../../../../../shared/utils/api/log-viewer.js'
import Modal from '../../../../../shared/components/modal.vue'
import SecondaryActionButton from '../../../../../shared/components/secondary-action-button.vue'
import IconButton from '../../../../../shared/components/icon-button.vue'

export default {
	components: { DataTable, SectionCard, ViewLayout, Modal, SecondaryActionButton, VueJsonPretty, IconButton, FilterBar },
	props: {
		submission: Object
	},
	data () {
		return {
			searchQuery: null,
			validJson: false,
			rawSubmissionString: null,
			showSubmissionStringModal: false,
			openSubmissionId: null,
			submissions: [],
			tableHeaders: [
				{ value: 'submissionId', text: 'ID', width: '10%' },
				{ value: 'limitedSubmission', text: 'Submission', width: '60%' },
				{ value: 'submissionDateTime', text: 'Submission Date', width: '20%' },
				{ value: 'action', text: 'Action', width: '10%', sortable: false }
			]
		}
	},
	computed: {
		logs () {
			return this.submissions.filter(({ submission }) => {
				let check = true
				if (this.searchQuery) check = submission.toLowerCase().includes(this.searchQuery.toLowerCase())
				return check
			})
		}
	},
	async created () {
		this.loadSubmissions()
	},
	methods: {
		async loadSubmissions () {
			const { data: submissions } = await getSubmissionLogs()
			this.submissions = submissions
		},
		openSubmissionModal (row) {
			this.openSubmissionId = row.submissionId
			this.loading = true
			try {
				this.validJson = true
				this.rawSubmissionString = JSON.parse(row.submission) ?? 'Submission String is not available'
			} catch (error) {
				this.validJson = false
				this.rawSubmissionString = row.submission ?? 'Submission String is not available'
			}
			this.showSubmissionStringModal = true
		},
		closeSubmissionModal () {
			this.rawSubmissionString = null
			this.validJson = false
			this.showSubmissionStringModal = false
			this.openSubmissionId = null
		}
	}
}
</script>
