import { format } from 'date-fns'
import { DATE_TIME_FORMAT } from '../date-formatting.js'
import { transactionalApi } from '../http-client.js'

export const getSubmissionLogs = () => {
	return transactionalApi.get('audit').then(({ data: { submissions } }) => JSON.parse(JSON.stringify(({
		data: submissions.map(submission => ({
			submissionId: submission.submissionId,
			submission: submission.submission,
			submissionDateTime: format(new Date(submission.submissionDateTime), DATE_TIME_FORMAT),
			limitedSubmission: submission.submission.substring(0, 50) + '  ...'
		}))
	}))))
}
